<template>
  <v-container fluid grey lighten-3 pb-10 pt-10>
    <v-row class="justify-center">
      <v-col>
        <v-card class="mx-auto" max-width="450">
          <v-card-title>เพิ่ม แบรนด์</v-card-title>
          <v-card-text>
            <v-form v-model="valid" ref="form" @submit.prevent="submit">
              <v-container>
                <v-row>


                  <v-col cols="12">
                    <v-text-field
                      v-model="product.name"
                      :rules="nameRules"
                      :counter="50"
                      label="ชื่อ แบรนด์"
                      id="name"
                      maxlength="50"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="product.tag"
                      :counter="3"
                      :rules="[
                        () =>
                          (!!product.tag && product.tag.length == 3) ||
                          'กรุณากรอกให้ถูกต้อง'
                      ]"
                      maxlength="3"
                      label="tag"
                      id="tag"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      v-model="product.no"
                      type="number"
                      label="No ลำดับ"
                      id="no"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-file-input
                      label="File input"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      type="file"
                      @change="onFileSelected"
                    ></v-file-input>
                    <div v-if="imageUrl">
                      <v-img
                        v-if="imageUrl"
                        :src="imageUrl"
                        max-height="150"
                        max-width="150"
                        class="mt-2"
                      ></v-img>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-switch
                      v-model="product.active"
                      label="Active"
                      color="success"
                      hide-details
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-divider class="mt-5 mb-5"></v-divider>
                <v-row>
                  <v-spacer></v-spacer>
                  <v-btn color="gray" class="mr-5" @click="reset">ยกเลิก</v-btn>
                  <v-btn :disabled="!valid" color="success" type="submit"
                    >บันทึก</v-btn
                  >
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api";

export default {
  name: "CategoryCreate",
  data() {
    return {
      product: {
        name: "",
        tag: "",
        image: "",
        no: 0,
        active: 0
      },
      nameRules: [
        v => !!v || "Name is required",
        v =>
          (typeof v != "undefined" && v.length <= 50) ||
          "Name must be less than 50 characters"
      ],
      imageUrl: null,
      valid: true
    };
  },
  methods: {
    async submit() {
      //alert(JSON.stringify(this.product))

      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("name", this.product.name);
        formData.append("tag", this.product.tag);
        formData.append("no", this.product.no);
        formData.append("active", this.product.active ? 1 : 0);
        formData.append("image", this.product.image);
        await api.addCategory(formData);
        this.$router.push("/category");
      }
    },
    onFileSelected(event) {
      const reader = new FileReader();
      if (typeof event == "undefined") {
        this.imageUrl = "";
      }

      reader.onload = event => {
        this.imageUrl = event.target.result;
      };

      if (event) {
        reader.readAsDataURL(event);
      }
      this.product.image = event;
    },
    cancel() {
      this.$router.push("/Category");
    },
    reset() {
      this.imageUrl = "";
      this.product.image = "";
      this.$refs.form.reset();
    }
  }
};
</script>

<style></style>
